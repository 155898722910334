:root {
  --mr-color-1: #f5f5f6;
  --mr-color-2: #3b3054;
  --mr-color-3: #94d2f1;
  --mr-color-4: #db5655;
  --mr-color-5: #fbd669;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Roboto", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Roboto", Sans-serif;
  font-weight: normal;
}

.max-width-20,
.max-width-30 {
  width: 100%;
}
@media (min-width: 577px) {
  .max-width-20 {
    max-width: 20%;
  }

  .max-width-30 {
    max-width: 30%;
  }
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: none;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.container-center {
  width: 100%;
  max-width: 390px;
  margin: auto;
}

.font-black {
  color: black !important;
}

.btn-mrz-primary,
.ant-btn-primary,
.btn-primary {
  background: #3b7779 !important;
  border-color: #3b7779 !important;
  box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  color: #ffffff;
}

.btn-mrz-secondary,
.ant-btn-default,
.btn-default {
  /* background: #FDB256 !important; */
  /* box-shadow: 0px 4px 14px rgb(217 217 217 / 25%); */

  background-color: #fffee5 !important;
  border: #fdb256;
  box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px;
  font-weight: 500;

  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  /* color: #FFFFFF; */
}

.btn-mrz-danger,
.ant-btn-danger,
.btn-danger {
  /* background: #FDB256 !important; */
  /* box-shadow: 0px 4px 14px rgb(217 217 217 / 25%); */

  background-color: #db5655 !important;
  border: #db5655;
  box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px;
  font-weight: 500;

  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  /* color: #FFFFFF; */
}

.btn-mrz-warning,
.ant-btn-warning,
.btn-warning {
  box-shadow: none;
  background: #fdb256 !important;
  border-color: #fdb256 !important;
  /* box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px; */
  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  color: #ffffff !important;
}

.mrz-bg-primary {
  background-color: #3b7779 !important;
}

.mrz-bg-warning {
  background-color: #fffee5 !important;
}

.mrz-bg-danger {
  background-color: #e15655 !important;
}

.mrz-badge-warning {
  background-color: #fffee5 !important;
  width: 100%;
  padding: 7px;
  box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px;
}

.mrz-badge-danger {
  background-color: #e15655 !important;
  width: 100%;
  padding: 7px;
  color: white;
  box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px;
}

.ant-modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
/* 
@media (max-width: 389px) {
  .modal-preview-content .ant-modal-body {
    padding: 24px 0;
  }
  .modal-preview-content .ant-modal-content {
    width: 365px;
  }
  .campaign-preview {
    margin-top: 230px;
  }
} */

/* .modal-preview-content .ant-modal-content .details .title {
  transform: scale(0.9);
  transform-origin: left;
}
.modal-preview-content .ant-modal-content .details .stamps {
  transform: scale(0.9);
  transform-origin: center;
}
.modal-preview-content .ant-modal-content .details .terms {
  transform: scale(0.9);
  transform-origin: left;
} */
.modal-preview-content .ant-modal-content {
  border-radius: 25px;
  /* max-height: 844px;  */
  width: 400px;
  margin: auto;
}
.campaign-preview {
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  padding: 0 28px;
  margin-top: 230px;
  width: 100%;
  text-align: left;
  height: 340px;
  overflow: auto;
}
.campaign-empty-preview {
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  padding: 0 28px;
  margin-top: 90px;
  width: 100%;
  text-align: left;
  height: 455px;
  overflow: auto;
}
.isReward {
  margin-top: 170px;
  height: 408px;
}

.ant-modal-content {
  border-radius: 25px;
  max-width: 436px;
  margin: auto;
}

.outlet-modal .ant-modal-content {
  border-radius: 25px;
  max-width: 800px !important;
  margin: auto;
}

.share-preview-modal .ant-modal-content {
  border-radius: 25px;
  max-width: 390px;
  margin: auto;
}

.preview-modal .ant-modal-content {
  border-radius: 25px;
  max-width: 800px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0 !important;
}

.bg-gradient-danger {
  background: linear-gradient(
    160deg,
    #db5655,
    #db5655,
    #db5655,
    #efc879
  ) !important;
  /* background: linear-gradient(125deg,#f5365c,#f56036)!important; */
}

.wallet-modal {
  color: #3b3054;
}

.wallet-modal-header {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.wallet-modal-header img {
  margin-right: 10px;
}

.wallet-modal-header span {
  font-size: 24px;
  font-weight: 500;
}

.wallet-modal-body {
  text-align: center;
}

.wallet-modal-body .price {
  font-size: 48px;
  font-weight: bold;
}

.wallet-modal-body p {
  font-size: 18px;
  font-weight: 500;
}

.wallet-modal-body .current {
  font-size: 20px;
  font-weight: bold;
  color: #db5655;
}

.wallet-modal-body .insufficient-balance {
  color: #db5655;
}

.wallet-modal-body button.top-up {
  background-color: #db5655;
  color: #f5f5f6;
  font-size: 20px;
  width: 100%;
  border-radius: 20px;
  padding: 14px;
}

.wallet-modal-body button.outline {
  background-color: transparent;
  color: #db5655;
  font-size: 20px;
  width: 100%;
  border: solid 1px #db5655;
  border-radius: 20px;
  padding: 14px;
}

.wallet-modal-body .option {
  color: #bebcc5;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.stipe-button {
  background: #635bff;
  border-radius: 3px;
  color: white;
  border: 0;
  padding: 10px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}

.question-mark {
  display: inline-block;
  /* background-color: #007bff; */
  background-color: #ffba54;
  height: 18px;
  width: 18px;
  border: none;
  border-radius: 50%;
  color: white;
  text-align: center;
  position: relative;
  margin-left: 6px;
}

.question-mark svg {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.box-primary {
  background-color: white;
  border-radius: 20px;
  padding: 50px;
  border: 0.75px solid #eaecee;
}

.box-secondary {
  background-color: #f1f1cf;
  padding: 12px;
}

.breadcrumbs {
  display: flex;
  background: #f5f5f6;
  padding: 6px 12px;
  border-radius: 24px;
  font-size: 16px;
  width: fit-content;
}
.breadcrumbs .child a {
  color: #212529;
}
.breadcrumbs .child:not(:last-child):after {
  content: "/";
  margin: 0px 3px;
}
.breadcrumbs .child:last-child {
  font-weight: 500;
}

.circularCrop .cropper-view-box {
  border-radius: 50%;
}
.circularCrop .cropper-face {
  background-color: inherit !important;
}

.mrz-timepicker div input {
  padding: 8px 6px !important;
}

@media (max-width: 576px) {
  .ant-picker-panels {
    flex-direction: column;
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

.referral-overlay {
  position: relative;
}
.referral-overlay .btn-overlay {
  z-index: 9;
}
.referral-overlay::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  top: 0;
  opacity: 0.1;
}

.mrz-steps .step {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 86%;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .mrz-steps .step {
    display: block;
  }
}
.mrz-steps .step:not(:last-child) {
  margin-bottom: 16px;
}
.mrz-steps .step:not(:last-child)::after {
  content: "";
  border: 1px solid #eaecee;
  height: 125%;
  position: absolute;
  left: 22px;
  top: 26px;
}
.mrz-steps .step div:first-child {
  position: relative;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgb(231, 231, 231);
  z-index: 1;
  margin-right: 12px;
  text-align: center;
}
.mrz-steps .step div:first-child span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translate(0%, -50%);
  color: rgb(231, 231, 231);
}
.mrz-steps .step div:nth-child(2) h4 {
  margin: 0;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
}
.mrz-steps .step div:nth-child(2) h4 a {
  color: #6b7280;
}
.mrz-steps .step div:nth-child(2) p {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  color: #3b7779;
  text-transform: capitalize;
}

.mrz-steps .step.checked div:first-child {
  background-color: #567877;
}
.mrz-steps .step.checked div:first-child span {
  color: white;
}

.Demo__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.Demo__container div {
  display: inline-block;
}

.Demo__some-network {
  /* margin: 0 13px;
  padding: 6px 0; */
  width: 25%;
}

.Demo__some p {
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}

.ant-modal-content {
  max-width: 1000px;
}

.ant-modal {
  width: auto !important;
}
