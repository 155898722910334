.gm-bundled-control {
  bottom: 0px;
  top: -75px;
}

.gm-fullscreen-control {
  display: none;
}

.gm-svpc {
  top: 160px;
}
